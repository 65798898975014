<template>
  <base-section id="toWho">
    <base-section-heading :title='$t("toWho.title")'>
    </base-section-heading>
    <div style="display: none; padding-bottom: 16px; max-width: 840px; text-align: center; margin: auto; font-weight: 800; font-size: 1rem;">
      {{ $t("toWho.subTitle") }}
    </div>
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row justify="center">
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="2"
            md="2"
            style="text-align: center;"
          >
            <v-row justify="center" no-gutters>
              <v-col cols="12">
                <v-icon large color="primary">
                  {{ card.icon }}
                </v-icon>
              </v-col>
              <v-col cols="12">
                <span style="font-weight: bold; color: rgba(0, 0, 0, 0.54);">
                  {{ $t(card.titleCode) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
    <v-row justify="center">
      <span style="padding: 16px; font-weight: bold; color: rgba(0, 0, 0, 0.54);">
        {{ $t("toWho.didntFind") }}
      </span>
    </v-row>
  </base-section>
</template>

<script>
export default {
  name: "ToWho",
  data: () => ({
    cards: [
      {
        icon: "mdi-cart-outline",
        titleCode: "toWho.eShops",
        title: "Online shop (sell, rent)",
        caseStudy: "test"
      },
      {
        icon: "mdi-food-outline",
        titleCode: "toWho.dining",
        title: "Restaurant, pubs and catering",
        caseStudy: "test"
      },
      {
        icon: "mdi-seat-outline",
        titleCode: "toWho.salons",
        title: "Salon and service centers",
        caseStudy: "test"
      },
      {
        icon: "mdi-home-clock-outline",
        titleCode: "toWho.renting",
        title: "Hotels, parking and leasing",
        caseStudy: null
      },
      {
        icon: "mdi-spa-outline",
        titleCode: "toWho.wellness",
        title: "Spa and wellness centers",
        caseStudy: null
      },
      {
        icon: "mdi-charity", //hospital-box-outline
        titleCode: "toWho.health",
        title: "Health and caring services",
        caseStudy: null
      },
      {
        icon: "mdi-gamepad-variant-outline",
        titleCode: "toWho.gaming",
        title: "Game and activity centers",
        caseStudy: null
      },
      {
        icon: "mdi-dumbbell",
        titleCode: "toWho.sports",
        title: "Gym, sports and fitness centers",
        caseStudy: null
      },
      {
        icon: "mdi-car-outline",
        titleCode: "toWho.vehicles",
        title: "Vehicle and washing centers",
        caseStudy: null
      },
      {
        icon: "mdi-wrench-outline",
        titleCode: "toWho.manufacturing",
        title: "Manufacturing and custom products",
        caseStudy: null
      },
      {
        icon: "mdi-party-popper",
        titleCode: "toWho.events",
        title: "Events and fairs",
        caseStudy: null
      },
      {
        icon: "mdi-forum-outline",
        titleCode: "toWho.counceling",
        title: "Counceling, legal advice, training",
        caseStudy: null
      }
    ]
  })
};
</script>
